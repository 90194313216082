import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BodyFatCalculationType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-calculation-type.enum';
import { ClientBodyFatMeasurementCreateRequestModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-create-request.model';
import { ClientBodyFatMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-response.model';

import { Subject } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'client-detail-body-composition-body-fat-measurement-sidebar-create-manual',
    templateUrl: './manual.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatButtonModule, MatTooltipModule, NgIf, MatProgressSpinnerModule]
})

export class ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateManualComponent implements OnChanges, OnDestroy
{
	@Input() bodyFatCalculationTypeList : {[key:number] : string}

	@Output() onCreated = new EventEmitter<ClientBodyFatMeasurementCreateRequestModel>();
	@Output() onCancel = new EventEmitter();

	@ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

	public selectedBodyFatCalculationType : BodyFatCalculationType = BodyFatCalculationType.Manual;
	public bodyFatCalculationTypes : typeof BodyFatCalculationType = BodyFatCalculationType;

	public showAlert: boolean = false;

	public bodyWeightMeasurement: ClientBodyFatMeasurementResponseModel = new ClientBodyFatMeasurementResponseModel();
	public bodyFatMeasurementForm : FormGroup;

	private _unsubscribeAll: Subject<any>;

	public constructor
	(
		private _formBuilder : FormBuilder
	)
	{
		this._unsubscribeAll = new Subject<any>();
	}

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	public create()
	{
		let bodyFatMeasurement = new ClientBodyFatMeasurementCreateRequestModel();

		bodyFatMeasurement = {...this.bodyFatMeasurementForm.value}
		bodyFatMeasurement.bodyFatCalculationTypeId = BodyFatCalculationType.Manual;

		this.resetForm();
		this.onCreated.emit(bodyFatMeasurement);
	}

	public cancel()
	{
		this.resetForm();
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	private buildForm()
	{
		this.bodyFatMeasurementForm = this._formBuilder.group
		(
			{
				value: ['', [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
				measurementDate: [new Date()]
			}
		);
	}

	private resetForm()
	{
		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective.resetForm();
			}
		);
	}
}
