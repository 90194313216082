import { Injectable } from '@angular/core';

import { BaseService } from '@fitness-central/api/base.service';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ClientBodyFatMeasurementCreateRequestModel } from './body-fat-measurement-create-request.model';
import { ClientBodyFatMeasurementResponseModel } from './body-fat-measurement-response.model';
import { ClientBodyFatMeasurementUpdateRequestModel } from './body-fat-measurement-update-request.model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ClientBodyFatMeasurementService extends BaseService
{
	public constructor
	(
	)
	{
		super();
	}

	public get(bodyFatId: string) : Observable<ClientBodyFatMeasurementResponseModel>
	{
		const url = environment.baseUrl + '/client/body-fat-measurement/' + bodyFatId;

		return this.httpClient.get<ClientBodyFatMeasurementResponseModel>(url);
	}

	public getByClientId(clientId : string, startDate : Date, endDate : Date) : Observable<ClientBodyFatMeasurementResponseModel[]>
	{
		const startDateString = super.convertToLuxonDateTime(startDate);
		const endDateString = super.convertToLuxonDateTime(endDate);

		const url = environment.baseUrl + `/client/body-fat-measurements/client/${clientId}/?startDate=${startDateString}&endDate=${endDateString }`;

		return this.httpClient.get<ClientBodyFatMeasurementResponseModel[]>(url);
	}

	public create(bodyFat: ClientBodyFatMeasurementCreateRequestModel): Observable<ClientBodyFatMeasurementResponseModel>
	{
		const url = environment.baseUrl + '/client/body-fat-measurement/';

		return this.httpClient.post<ClientBodyFatMeasurementResponseModel>(url, bodyFat);
	}

	public update(bodyFat: ClientBodyFatMeasurementUpdateRequestModel): Observable<ClientBodyFatMeasurementResponseModel>
	{
		const url = environment.baseUrl + '/client/body-fat-measurement/' + bodyFat.bodyFatMeasurementId;

		return this.httpClient.put<ClientBodyFatMeasurementResponseModel>(url, bodyFat);
	}

	public delete(bodyFatId: string): Observable<ClientBodyFatMeasurementResponseModel>
	{
		const url = environment.baseUrl + '/client/body-fat-measurement/' +bodyFatId;

		return this.httpClient.delete<ClientBodyFatMeasurementResponseModel>(url);
	}
}
