import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BodyFatCalculationType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-calculation-type.enum';
import { ClientBodyFatMeasurementCreateRequestModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-create-request.model';
import { ClientBodyFatMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-response.model';
import { ClientBodyFatSiteMeasurementRequestModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-site-measurement/body-fat-site-measurement-request.model';
import { ClientBodyFatSiteMeasurementType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-site-measurement/body-fat-site-measurement-type.enum';
import { GenderType } from '@fitness-central/api/general/gender-type/gender-type.enum';

import { Subject } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgSwitch, NgSwitchCase, NgIf } from '@angular/common';

@Component({
    selector: 'client-detail-body-composition-body-fat-measurement-sidebar-create-jackson-pollock-3-site',
    templateUrl: './jackson-pollock-3-site.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgSwitch, NgSwitchCase, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatButtonModule, MatTooltipModule, NgIf, MatProgressSpinnerModule]
})

export class ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateJacksonPollock3SiteComponent implements OnChanges, OnDestroy
{
	@Input() clientGender : GenderType;
	@Input() bodyFatCalculationTypeList : {[key:number] : string}

	@Output() onCreated = new EventEmitter<ClientBodyFatMeasurementCreateRequestModel>();
	@Output() onCancel = new EventEmitter();

	@ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

	public selectedBodyFatCalculationType : BodyFatCalculationType = BodyFatCalculationType.Manual;
	public bodyFatCalculationTypes : typeof BodyFatCalculationType = BodyFatCalculationType;

	public showAlert: boolean = false;

	public bodyWeightMeasurement: ClientBodyFatMeasurementResponseModel = new ClientBodyFatMeasurementResponseModel();
	public bodyFatMeasurementForm : FormGroup;

	public genderType : typeof GenderType = GenderType;

	private _unsubscribeAll: Subject<any>;

	public constructor
	(
		private readonly _formBuilder : FormBuilder
	)
	{
		this._unsubscribeAll = new Subject<any>();
	}

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	public create()
	{
		let bodyFatMeasurement = new ClientBodyFatMeasurementCreateRequestModel();

		bodyFatMeasurement.bodyFatCalculationTypeId = BodyFatCalculationType.JacksonPollock3Site;
		bodyFatMeasurement.measurementDate = this.bodyFatMeasurementForm.get("measurementDate").value;

		if (this.clientGender == GenderType.Male)
		{
			let abdomen = new ClientBodyFatSiteMeasurementRequestModel();
			abdomen.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Abdomen;
			abdomen.value = this.bodyFatMeasurementForm.get("abdomen").value;
			bodyFatMeasurement.bodyFatSiteMeasurements.push(abdomen);

			let chest = new ClientBodyFatSiteMeasurementRequestModel();
			chest.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Chest;
			chest.value = this.bodyFatMeasurementForm.get("chest").value;
			bodyFatMeasurement.bodyFatSiteMeasurements.push(chest);

			let thigh = new ClientBodyFatSiteMeasurementRequestModel();
			thigh.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Thigh;
			thigh.value = this.bodyFatMeasurementForm.get("thigh").value;
			bodyFatMeasurement.bodyFatSiteMeasurements.push(thigh);
		}

		if (this.clientGender == GenderType.Female)
		{
			let thigh = new ClientBodyFatSiteMeasurementRequestModel();
			thigh.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Thigh;
			thigh.value = this.bodyFatMeasurementForm.get("thigh").value;
			bodyFatMeasurement.bodyFatSiteMeasurements.push(thigh);

			let tricep = new ClientBodyFatSiteMeasurementRequestModel();
			tricep.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Triceps;
			tricep.value = this.bodyFatMeasurementForm.get("tricep").value;
			bodyFatMeasurement.bodyFatSiteMeasurements.push(tricep);

			let suprailiac = new ClientBodyFatSiteMeasurementRequestModel();
			suprailiac.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Suprailiac;
			suprailiac.value = this.bodyFatMeasurementForm.get("suprailiac").value;
			bodyFatMeasurement.bodyFatSiteMeasurements.push(suprailiac);
		}

		this.resetForm();
		this.onCreated.emit(bodyFatMeasurement);
	}

	public cancel()
	{
		this.resetForm();
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	private buildForm()
	{
		if (this.clientGender == GenderType.Male)
		{
			this.bodyFatMeasurementForm = this._formBuilder.group
			(
				{
					abdomen: ['', [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
					chest: ['', [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
					thigh: ['', [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
					measurementDate: [new Date()]
				}
			);
		}

		if (this.clientGender == GenderType.Female)
		{
			this.bodyFatMeasurementForm = this._formBuilder.group
			(
				{
					thigh: ['', [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
					tricep: ['', [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
					suprailiac: ['', [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
					measurementDate: [new Date()]
				}
			);
		}
	}

	private resetForm()
	{
		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective.resetForm();
			}
		);
	}
}
